<template>
  <div class="content flex justify-center align-middle">
    <div class="w-full max-w-md">
      <div class="relative mt-2 flex items-center">
        <input
          name="search"
          id="search"
          ref="searchInput"
          v-model.lazy="searchTerm"
          type="search"
          placeholder="Suche"
          @keydown.enter="handleSearch"
          class="block w-full rounded-md border-0 py-1.5 pl-4 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <kbd
            class="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400"
            >⌘K</kbd
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useSearchStore } from "~/stores/search";
import { toRefs, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useEventListener } from "@vueuse/core";
const searchStore = useSearchStore();
const { searchTerm } = toRefs(searchStore);
const searchInput = ref<HTMLInputElement | null>(null);
if (process.client) {
  useEventListener(window, "keydown", (event) => {
    if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
      searchInput.value?.focus();
    }
  });
}

const router = useRouter();

const handleSearch = async () => {
  await navigateTo("/search");
};
</script>
